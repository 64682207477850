import React from "react";
import {
  Privacy_Policy_title,
  Privacy_Policy_image,
  half_circle_bg_image,
  half_circle_bg_image2,
  book_logo3,
  Proschool_logo,
  instagrme_icon,
  facebook_icon,
  youtube_icon,
  linkedin_icon,
  twitter_icons
} from "../../assets/images/images";
import { NavLink, useNavigate } from "react-router-dom";
import HomeNavbar from "../home/components/HomeNavbar";
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from "react-helmet";
import "./PrivacyPolicy.css";

const PrivacyPolicy = () => {

  const navigate = useNavigate();
  const globalState = useSelector((state) => state.globalState);
  const subadmin_details = globalState.subadmin_details;
  const handleClick = (e) => {
    e.preventDefault(); // Prevent default link behavior
    // Navigate to the home page
    navigate("/");

    // Use setTimeout to delay the scroll to the specific section
    setTimeout(() => {
      const element = document.getElementById("revolution_sec_new");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }, 100); // Adjust the timeout as needed
  };

  return (
    <>


      <Helmet>
        <title> ProSchool.ai Privacy Policy - Protecting Your Data</title>
        <meta name="description" content=" Your privacy matters at ProSchool.ai. Read our policy on how we protect your data in our AI-powered educational ecosystem." />
        <meta name="keywords" content=" Privacy policy, data protection, ProSchool.ai privacy, secure learning, educational privacy" />
        {/* Add more meta tags as needed */}
      </Helmet>

      <h1 style={{ display: 'none' }}> ProSchool.ai Privacy Policy </h1>
      <HomeNavbar />
      <div className="Terms-and-Conditions-section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="Terms-and-Conditions-wrapper">
                <div className="Privacy_Policy_title">
                  <img src={Privacy_Policy_title} />
                </div>
                <div className="Terms-and-Conditions-image">
                  <img src={Privacy_Policy_image} />
                </div>
                <div className="Privacy_policy-wrapper">
                  <div className="Terms-and-Conditions-list-foldre">
                    <h2>1. Introduction</h2>
                    <p>
                      Welcome to ProSchool.ai, an innovative Learning Management System (LMS) platform. We are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy explains how we collect, use, disclose, and protect the personal information of users on our platform. By accessing or using our services, you signify your acceptance of the terms and provide us with your consent to the practices outlined in this policy. Our commitment to transparency means we strive to inform our users about how their data is handled. We encourage you to read this Privacy Policy carefully. By continuing to use our services, you acknowledge that you have read, understood, and agreed to this Privacy Policy.
                    </p>
                  </div>
                  <div className="Terms-and-Conditions-list-foldre">
                    <h2>2. INFORMATION WE COLLECT</h2>
                    <ul className="Privacy_Policy_list">
                      <li>
                        <span>a.Information You Provide:</span>
                        <p>When you use our services, you may provide us with personal information such as your name, contact information, educational background, and other relevant data. This may include, but is not limited to, academic qualifications, enrollment details, feedback, coursework submissions, and communications with instructors. We access files and associated metadata (such as file names, creation dates, and folder structures) stored in your Google Drive to enable file management, sharing, and submission functionalities within the platform.</p>
                      </li>
                      <li>
                        <span>b.Information We Collect Automatically:</span>
                        <p>We may automatically collect certain information about your use of our services, device information, browser type, access times, and usage patterns. This helps us to understand how you interact with our platform and improve your user experience.</p>
                      </li>
                      <li>
                        <span>c.Cookies and Similar Technologies:</span>
                        <p>We may automatically collect certain information about your use of our services, device information, browser type, access times, and usage patterns. This helps us to understand how you interact with our platform and improve your user experience.</p>
                      </li>
                    </ul>
                  </div>
                  <div className="Terms-and-Conditions-list-foldre">
                    <h2>3. HOW WE USE YOUR INFORMATION</h2>
                    <ul className="Privacy_Policy_list">
                      <li>
                        <span>a.Personalized Learning Experience:</span>
                        <p>We use the information we collect to provide, maintain, and improve our services, including offering personalized learning paths and resources tailored to your needs. Our AI algorithms analyze your educational history, preferences, and performance data to suggest relevant courses, learning materials, and study tips.</p>
                      </li>
                      <li>
                        <span>b.Communication:
                        </span>
                        <p>We may use your information to communicate with you about our services, including updates, educational resources, and administrative messages. Notifications about new courses or features are sent periodically, while important administrative messages are delivered as necessary.
                        </p>
                      </li>
                      <li>
                        <span>c.File Upload and Storage</span>
                        <p>We may use your information for research and analytics purposes to understand trends in educational data, evaluate learning outcomes, and enhance our AI algorithms. This helps us to continuously improve our platform and offer a better learning experience. Teachers can share files with students by setting specific permissions (e.g., view-only, edit), ensuring controlled access to educational content. Students can select files from their Google Drive to submit as assignments, making the process more accessible. Teachers can organize files within Google Drive into folders for different courses or assignments, aiding in the efficient management of educational resources.
                        </p>
                      </li>
                      <li>
                        <span>d.Research and Analytics
                        </span>
                        <p>We may use your information for research and analytics purposes to understand trends in educational data, evaluate learning outcomes, and enhance our AI algorithms. This helps us to continuously improve our platform and offer a better learning experience.
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div className="Terms-and-Conditions-list-foldre">
                    <h2>4. HOW WE SHARE YOUR INFORMATION</h2>
                    <ul className="Privacy_Policy_list">
                      <li>
                        <span>a.Third-Party Service Providers:
                        </span>
                        <p>We may share your information with third-party service providers who assist us in delivering our services, such as cloud hosting providers, analytics platforms, and educational content partners. These providers are contractually obligated to protect your information and use it only for the services they provide to us. ProSchool.ai also utilizes the services of Google, including Google Drive, to enhance the delivery and security of our educational services. By using our platform, you acknowledge and agree that your data may be processed and stored on Google’s servers.
When you interact with certain features of our platform, such as saving documents or sharing educational materials, your information may be stored on Google Drive. This data storage is subject to Google's privacy practices, which include the following:
By continuing to use ProSchool.ai, you consent to this data sharing practice and acknowledge that your data may be subject to Google’s privacy policy, which can be reviewed here.
</p>
                      </li>
                      <li>
                        <span>b.Educational Institutions and Partners:
                        </span>
                        <p>We may share your information with educational institutions or partners involved in delivering or enhancing the educational experience. These partners are required to protect your information and use it only for the agreed-upon purposes.

                        </p>
                      </li>
                      <li>
                        <span>c.Legal Requirements:</span>
                        <p>We may disclose your information if required by law or legal process or if we believe that such disclosure is necessary to protect our rights, property, or safety, or the rights, property, or safety of others.
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div className="Terms-and-Conditions-list-foldre">
                    <h2>
                      5. PURPOSE OF DATA COLLECTION

                    </h2>
                    <p>
                    At ProSchool.ai, we collect data to provide a seamless, personalized, and enhanced educational experience for our users. Mentioned below is a detailed look at how we utilize the data collected:
                    </p>
                    <ul className="Privacy_Policy_list">
                      <li>
                        <span>a.Personalized Learning Paths:
                        </span>
                        <p>We use data such as your educational background, course selections, and interaction history to tailor learning paths that suit your unique needs and goals. Our AI algorithms analyze this information to suggest courses, study materials, and learning strategies that optimize your educational journey.</p>
                      </li>
                      <li>
                        <span>b.Platform Enhancement:

                        </span>
                        <p>Data collected from user interactions helps us improve our Learning Management System by identifying areas for enhancement and ensuring that our platform remains user-friendly, efficient, and relevant to your educational needs.

                        </p>
                      </li>
                      <li>
                        <span>c.Communication and Support:
                        </span>
                        <p>We use your contact information to provide timely updates about new features, course offerings, and important notifications. This ensures you stay informed and receive support when needed, enhancing your overall experience on our platform
                        </p>
                      </li>
                      <li>
                        <span>d.User Experience Optimization:
                        </span>
                        <p>We gather data on how users navigate and interact with the platform to enhance user experience. This includes optimizing site performance, simplifying navigation, and providing a more intuitive interface.

                        </p>
                      </li>
                    </ul>
                  </div>
                  <div className="Terms-and-Conditions-list-foldre">
                    <h2>
                      6. DATA SECURITY

                    </h2>
                    <p className="childrens-online-privacy-protection-act">
                      {/* Our platform complies with the Children's Online Privacy
                      Protection Act (COPPA). We do not knowingly collect
                      personal information from children under the age of 13. If
                      it comes to our attention that we have inadvertently
                      collected such information, we will promptly take steps to
                      delete it. */}
                      ProSchool.ai prioritizes the protection of your personal information with rigorous security measures. We use encryption to secure data in transit and at rest, ensuring your information remains confidential. Access to data is restricted to authorized personnel who adhere to strict protocols. Regular security audits and assessments help us identify and address potential vulnerabilities. In the event of a security incident, we have a response plan to resolve issues promptly and notify affected users if necessary. We encourage you to use strong passwords and keep your login information private.

                      {/* <ul className="Terms-and-Conditions-list">
                        <span>1. ProSchool.ai collects the following information:</span>
                        <li>Information You Provide: When you use our services, you may provide us with personal information of the child such as the name, contact information, email address necessary for the creation of an account.</li>
                        <li>Information We Collect Automatically: We may automatically collect certain information about the child’s use of our services, including Geolocation data and device information along with browser type and usage patterns. The data collected may also include, but is not limited to, academic performance, learning preferences, and interaction history.</li>
                      </ul>
                      <ul className="Terms-and-Conditions-list">
                        <span>2. How ProSchool.ai uses the Information:</span>
                        <li>The information collected is used exclusively for educational purposes. It aids in customizing lessons, generating personalized assessments, and enhancing the overall learning experience on our platform.</li>
                        <li>We also use the information we collect to provide, maintain, and improve the services and insights based on AI analysis.</li>
                      </ul>
                      <ul className="Terms-and-Conditions-list">
                        <span>3. Disclosure of information collected</span>
                        <li>We do not provide personal information of the Children to any third party operators, such as advertisers, analytics providers, or service providers. The information collected is specifically used as to how we use your information.</li>
                      </ul>
                      <ul className="Terms-and-Conditions-list">
                        <span>4. Data Deletion</span>
                        <li>The consent provided to us is revocable and the personal information provided to us will be deleted from our servers within a reasonable time period of 20-30 days except where ProSchool.ai is required to retain copies under applicable laws, in which case ProSchool.ai will isolate and protect such personal data from any further processing except to the extent required by applicable laws.</li>
                      </ul> */}
                    </p>
                  </div>
                  <div className="Terms-and-Conditions-list-foldre">
                    <h2>7. EU GENERAL DATA PROTECTION REGULATION (GDPR) COMPLIANCE
                    </h2>
                    <p>
                    ProSchool.ai adheres to the EU General Data Protection Regulation (GDPR) to protect user privacy. We process personal data lawfully and transparently, ensuring users have rights to access, correct, and delete their data. Our security measures include encryption and access controls, and we ensure data transfers outside the EEA have appropriate safeguards. Our Data Protection Officer oversees compliance and addresses concerns.
                    </p>
                  </div>
                  <div className="Terms-and-Conditions-list-foldre">
                    <h2>8. CHILDREN’S ONLINE PRIVACY PROTECTION ACT (COPPA) COMPLIANCE 
                    </h2>
                    <p>
                    We obtain Verifiable Parental Consent for children under the age of 13 Years intending to use our services and Verifiable Parental Consent from Educational Institutions / Teachers acting as the parents’ agents if the targeted audience intended to use our services are 13 Years and below.
Please read our Collection, Use and Disclosure Practices mentioned below.

                    </p>
                    <ul className="Terms-and-Conditions-list">
                        <span>1. ProSchool.ai collects the following information:</span>
                        <li>Information You Provide: When you use our services, you may provide us with personal information of the child such as the name, contact information, email address necessary for the creation of an account.</li>
                        <li>Information We Collect Automatically: We may automatically collect certain information about the child’s use of our services, including Geolocation data and device information along with browser type and usage patterns. The data collected may also include, but is not limited to, academic performance, learning preferences, and interaction history.</li>
                      </ul>
                      <ul className="Terms-and-Conditions-list">
                        <span>2. How ProSchool.ai uses the Information:</span>
                        <li>The information collected is used exclusively for educational purposes. It aids in customizing lessons, generating personalized assessments, and enhancing the overall learning experience on our platform.</li>
                        <li>We also use the information we collect to provide, maintain, and improve the services and insights based on AI analysis.</li>
                      </ul>
                      <ul className="Terms-and-Conditions-list">
                        <span>3. Disclosure of information collected</span>
                        <li>We do not provide personal information of the Children to any third party operators, such as advertisers, analytics providers, or service providers. The information collected is specifically used as to how we use your information.</li>
                      </ul>
                      <ul className="Terms-and-Conditions-list">
                        <span>4. Data Deletion</span>
                        <li>The consent provided to us is revocable and the personal information provided to us will be deleted from our servers within a reasonable time period of 20-30 days except where ProSchool.ai is required to retain copies under applicable laws, in which case ProSchool.ai will isolate and protect such personal data from any further processing except to the extent required by applicable laws.</li>
                      </ul>
                  </div>
                  <div className="Terms-and-Conditions-list-foldre">
                    <h2>9. DATA SHARING
                    </h2>
                    <p>
                    All content provided on ProSchool.ai, including courses, lessons, assessments, and any other educational materials, is the intellectual property of the respective creators and ProSchool.ai. Users are strictly prohibited from reproducing, distributing, modifying, or using this content for 
                    any purpose outside the platform without explicit written permission from the content creators or ProSchool.ai. Any unauthorized use of the content may result in termination of access to our services and could lead to legal action.
                    </p>
                  </div>
                  <div className="Terms-and-Conditions-list-foldre">
                    <h2>10. AI CHATBOT INTERACTIONS
                    </h2>
                    <p>
                    ProSchool.ai offers personalized AI chatbot interactions for both students and teachers to enhance communication and support the learning experience. These interactions are monitored to improve the effectiveness of our educational services and to tailor responses to individual needs. However, the information collected through these interactions is not used for any purposes other than enhancing the learning experience and platform functionality. We prioritize your privacy and ensure that any data obtained from chatbot interactions is handled in accordance with our strict privacy and security measures.

                    </p>
                  </div>
                  <div className="Terms-and-Conditions-list-foldre">
                    <h2>11. DATA RETENTION</h2>
                    <p>
                    We retain user data for as long as the user’s account is active. If a user wishes to delete their account and associated data, they can request deletion by contacting us. Upon receiving a deletion request, we will promptly remove the user's data, subject to any legal obligations to retain certain information. We are committed to ensuring that your data is only kept for as long as necessary to provide our services effectively.

                    </p>
                  </div>
                  <div className="Terms-and-Conditions-list-foldre">
                    <h2>12. CHANGES TO PRIVACY POLICY</h2>
                    <p>
                    ProSchool.ai may update or modify this Privacy Policy at any time to reflect changes in our practices or to comply with legal requirements. The updated policy will be effective immediately upon posting on our website. We encourage you to periodically review this Privacy Policy to stay informed about how we protect your information.
                    Your continued use of our services after any changes to the Privacy Policy will constitute your acknowledgment and acceptance of those changes. If you do not agree with the updated policy, you should discontinue using our services.


                    </p>
                  </div>
                  <div className="Terms-and-Conditions-list-foldre">
                    <h2>13. CONTACT INFORMATION</h2>
                    <p>
                    If you have any questions, concerns, or inquiries regarding the Privacy Policy, or if you need assistance with the ProSchool platform, please feel free to contact us support@arcitech.ai.



                    </p>
                  </div>
                </div>
                <div className="half-circle-bg-image">
                  <img src={half_circle_bg_image} />
                </div>
                <div className="half-circle-bg-image2">
                  <img src={half_circle_bg_image2} />
                </div>
                <div className="book-logo3">
                  <img src={book_logo3} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-section" id="footer-section">
        <div className="footer-wraper">
          <div className="footer-link-folder">
            <div className="proschool-logo">
              <img src={subadmin_details ? subadmin_details?.school_image : Proschool_logo} />
            </div>
            <p>We are more than just an LMS; we are a catalyst for comprehensive educational transformation. ProSchool.ai brings a multidimensional approach to education,
              contrasting with traditional methods.
            </p>
            {/* <div className="footer-Join-our-newsletter-folder">
              <p>Join our newsletter</p>
              <div className="footer-Join-our-newsletter-search-folder">
                <input type="email" placeholder="Your email"></input>
                <button>Subscribe</button>
              </div>
            </div> */}
          </div>
          <div className="footer-Quick-Links-folder">
            <h2>Quick Links</h2>
            <ul className="footer-Quick-Links">
              <li>
                <NavLink to="/">Home</NavLink>
              </li>
              <li>
                <a href="revolution_sec_new" onClick={handleClick}>
                  Features
                </a>
              </li>
              <li>
                <NavLink to="/about">About Us</NavLink>
              </li>
              <li>
                <NavLink to="/terms-and-conditions">Terms & Conditions</NavLink>
              </li>
              <li>
                <NavLink to="/privacy-policy">Privacy Policy</NavLink>
              </li>
            </ul>
          </div>
          <div className="footer-Quick-Links-folder">
            <h2>Contact</h2>
            <div className="footer-contact">
              {/* <div className="footer-contact-folder">
                <a>
                  <i class="fa-solid fa-phone"></i>
                </a>
                <span>
                <a href="tel:+1 (646) 222-3901">{subadmin_details ? subadmin_details?.phone : '+1 (646) 222-3901'}</a>
                </span>
              </div> */}
              <div className="footer-contact-folder">
                <a>
                  <i class="fa-solid fa-envelope"></i>
                </a>
                {subadmin_details ? (
                  <a href={`mailto:${subadmin_details?.email}`}>{subadmin_details.email}</a>
                ) : (
                  <a href="mailto: info@proschool.ai">info@proschool.ai</a>
                )}


              </div>
              {/* <div className="footer-contact-folder">
                <a>
                  <i class="fa-solid fa-location-dot"></i>
                </a>
                {subadmin_details ? (
                  subadmin_details?.location
                ) : (
                  <a
                    href="https://www.google.com/maps/place/Arcitech+-+AI+Software,+Web,+App+Development/@19.0020445,72.8229651,16z/data=!4m10!1m2!2m1!1sA-802,+2nd+floor,+Trade+Link,+Kamala+Mills,+Lower+Parel,+Mumbai,+Maharashtra+400013!3m6!1s0x3be7ce9336955555:0x743645ef3c0b1a05!8m2!3d19.0033511!4d72.8274615!15sClNBLTgwMiwgMm5kIGZsb29yLCBUcmFkZSBMaW5rLCBLYW1hbGEgTWlsbHMsIExvd2VyIFBhcmVsLCBNdW1iYWksIE1haGFyYXNodHJhIDQwMDAxM1pPIk1hIDgwMiAybmQgZmxvb3IgdHJhZGUgbGluayBrYW1hbGEgbWlsbHMgbG93ZXIgcGFyZWwgbXVtYmFpIG1haGFyYXNodHJhIDQwMDAxM5IBEHNvZnR3YXJlX2NvbXBhbnmaASNDaFpEU1VoTk1HOW5TMFZKUTBGblNVTkxiMHBEVm1ObkVBReABAA!16s%2Fg%2F11lt56gqrs?entry=ttu"
                    target="_blank"
                  >
                    A-802, 2nd floor, Trade Link, Kamala Mills, Lower Parel,
                    Mumbai, Maharashtra 400013
                  </a>
                )}
              </div> */}
            </div>
          </div>
          <div className="footer-get-in-touch-folder">
            <span>Get in touch</span>
            <div className="footer-social-link-folder">
              <a
                href="https://www.facebook.com/proschoolai/"
                target="_blank"
              >
                {/* <i className="fa-brands fa-facebook"></i> */}
                <img src={facebook_icon} />
              </a>
              <a
                href="https://twitter.com/Proschoolai"
                target="_blank"
              >
                {/* <i class="fa-brands fa-x-twitter"></i> */}
                <img src={twitter_icons} />
              </a>
              <a
                href="https://www.instagram.com/proschool.ai/"
                target="_blank"
              >
                {/* <i className="fa-brands fa-instagram"></i> */}
                <img src={instagrme_icon} />
              </a>
              <a
                href="https://www.linkedin.com/company/proschool-ai"
                target="_blank"
              >
                {/* <i class="fa-brands fa-linkedin"></i> */}
                <img src={linkedin_icon} />
              </a>
              <a
                href="https://www.youtube.com/@ProSchoolAI"
                target="_blank"
              >
                {/* <i class="fa-brands fa-youtube"></i> */}
                <img src={youtube_icon} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
